.container{
  padding-top: 1rem;
  padding-bottom: 5.125rem;
  display: flex;
  flex-wrap: wrap;
}

.row{ 
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.spinner {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.card {
  width: 100%;
}

.footer{
  display: flex;
  justify-content: center;
}

@media(min-width: 992px){
  .hideOnDesktop {
    display: none;
  }
}