.productCard {
  margin: 3px !important;
  background-color: #fcfcfc !important;
}

.noClick {
  pointer-events: none;
}

.infoContainer {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}
.outOfStock {
  position: absolute;    
  background-color: #21212023;
  height: 10rem;
  width: 100%;
}
.outOfStockText{
  font-size: 2rem;
  font-weight: bold;
  color: #28a745;
  margin-top: 55px;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: #28a745;
  text-align: center;
}
.infoContainerCentered {
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.infoContainerCenteredGreen {
  font-weight: 700;
  display: flex;
  justify-content: center;
  color: #2eb82e;
}
.image {
  width: unset !important;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 12rem;
}

.Indica, .Hybrid, .Sativa {
  border: 1px solid transparent;
  color: white;
}

.Indica {
  background-color: #bf00ff;
  border-color: #8600b3;
}

.Hybrid {
  background-color: #70db70;
  border-color: #2eb82e;
}

.Sativa {
  background-color: #e6e600;
  border-color: #999900;
  color: #4d4d00;
}

.delivery {
  display: flex;
  align-items: center;
}

.delivery > p {
  margin-bottom: 0;
  padding-right: 0.25rem;
}

.brand{
  margin-bottom: 0.25rem;
  text-align: center;
  font-weight: 400;
}

@media (min-width: 576px){
  .productCard {
    min-width: 48.89% !important;
  }
}

@media (min-width: 768px) {
  .productCard {
    min-width: 32.5% !important;
  }
}

@media (min-width: 992px) {
  .productCard {
    min-width: 24% !important;
    max-width: 24%;
  }

  .productCard.modalCard {
    max-width: 50%;
    min-width: 50% !important;
  }

  .imageContainer {
    height: 9rem;
  }
}

@media (min-width: 1200px) {
  .productCard {
    min-width: 24.47% !important;
    max-width: 24.47%;
  }
}