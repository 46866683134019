@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
.Toast_toast__2Hubc {
  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 1051;
  transition: z-index .15s linear, opacity .15s linear;
}

.Toast_toastContainer__1M1yG {
  display: flex;
  justify-content: center;
}

.Toast_toast__2Hubc.Toast_success__30BVA{
  border-color: #28a745;
  background-color: #5bd778;
  color: #0f3e1a
}

.Toast_header__3G_qv.Toast_success__30BVA {
  background-color: #28a745;
  color: white;
}

.Toast_toast__2Hubc.Toast_danger__1L4pV{
  border-color: #dc3545;
  background-color: #e46774;
  color: #570f16
}

.Toast_header__3G_qv.Toast_danger__1L4pV {
  background-color: #dc3545;
  color: white;
}

.Toast_toast__2Hubc.Toast_hidden__1DByE{
  z-index: -1;
}
.Login_loginButton__2jPl0 {
  display: flex;
  justify-content: flex-end;
}

.Login_register__3QgNw{
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid #ccc;
}
.ContactUs_buttonGroup__2LNu7 {
  display: flex;
  justify-content: flex-end;
}

.ContactUs_buttonGroup__2LNu7 button{
  margin-left: 0.5rem
}
.BuyerOrSeller_buyorsellContainer__2OAzh {
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
}

.BuyerOrSeller_buyorsellText__3DjBD {
  padding-top: 1rem;
  text-align: center;
}

.BuyerOrSeller_button__OMpZ2 {
  width: 9rem;
}




.Navigation_navbar__l0XoK {
  padding: 0;
  height: 66px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #306a39;
}

.Navigation_navbarBottom__3FDz5 {
  padding-top: 20px;
  height: 40px;
  background-color: whitesmoke;
  color: black;
  text-decoration: none;
  border-top: 1px solid #306a39;
}

.Navigation_navbarBottom__3FDz5 p {
  cursor: pointer;
}

.Navigation_maxHeight__3PpWq {
  height: 100%
}

.Navigation_link__225AM {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  padding: 1rem !important;
  font-weight: bold;
  color: #306a39 !important;
  height: 100%;
}

.Navigation_active__1S0go {
  background-color: #306a39 !important;
  color: whitesmoke !important
}

.Navigation_collapse__1kxKW {
  position: absolute;
  top: 66px;
  width: 100%;
  left: 0;
  z-index: 1;
}

.Navigation_container__eibGo {
  padding: 0 1rem !important; 
  height: 100%;
  justify-content: space-around;
  display: flex;
}

.Navigation_dropdown__1E0xO {
  display: none;
}

.Navigation_logo__oRTeI{
  height: 33px;
}

.Navigation_showOnDesktop__jUqN6 {
  display: none;
}

.Navigation_countBadge__2EUi3 {
  position: absolute;
  top: 10px;
  margin-left: -10px;
  padding: 0 .5rem;
  color: whitesmoke;
  background-color: #306a39;
  border-radius: 100%;
  border: 2px solid whitesmoke
}

.Navigation_cartContainer__WTHkF {
  margin-right: 0.75rem
}

@media(min-width: 768px){
  .Navigation_logo__oRTeI{
    height: 40px;
  }
}

@media(min-width: 992px) {

  .Navigation_hideOnDesktop__E5sjW{
    display: none
  }

  .Navigation_showOnDesktop__jUqN6 {
    display: block;
  }

  .Navigation_link__225AM {
    border: none;
    border-bottom: 5px solid transparent;
    background-color: #f5f5f5 !important;
    color: #306a39 !important;
    display: none;
  }

  .Navigation_active__1S0go{
    color: #306a39 !important;
    border-bottom: 5px solid #306a39 !important;
  }

  .Navigation_collapse__1kxKW {
    position: relative;
    top: unset;
  }

  .Navigation_container__eibGo {
    padding: 0;
  }

  .Navigation_userLinks__1eawC {
    display: none;
  }

  .Navigation_permanent__3l6c7 {
    display: block !important;
  }

  .Navigation_dropdown__1E0xO {
    display: flex;
    margin-top: 4px;
    border: 5px solid transparent;
  }

  .Navigation_dropdown__1E0xO a:first-of-type{
    margin-top: -19px;
  }

  .Navigation_dropdown__1E0xO a, .Navigation_dropdown__1E0xO div {
    color: #306a39 !important;
    background-color: #f5f5f5 !important;
  }

  .Navigation_dropdown__1E0xO div div{
    border-color: #306a39 !important;
  }

  .Navigation_dropdown__1E0xO {
    align-items: center;
    height: 68px;
    font-weight: bold;
    color: #306a39 !important;
  }

  .Navigation_cart__22poW {
    margin-left: 3rem
  }
}

.AgeVerify_ageVerifyContainer__2ZQrL {
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
}

.AgeVerify_ageVerifyText__2f0ce {
  padding-top: 1rem;
  text-align: center;
}

.AgeVerify_button__HGFOI {
  width: 9rem;
}
.Search_searchBar__qp0cs {
  width: 100%;
}

.Search_searchBarContainer__nqH5z {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Search_formGroup__3PSc0{
  margin-bottom: 0.25rem;
}

.Search_button__zbrvV{
  width: 100%;
  margin-bottom: 0.5rem;
}

@media(min-width: 576px) {
  .Search_searchBar__qp0cs{
    width: 80%;
  }

  .Search_formGroup__3PSc0{
    margin-bottom: 1rem;
  }
}

@media(min-width: 768px){
  .Search_button__zbrvV{
    margin-bottom: 0;
  }
}

@media(min-width: 992px) {
  .Search_searchBar__qp0cs{
    width: 60%;
  }
}
.ProductListItem_productCard__1DwhT {
  background-color: #fcfcfc !important;
  margin: 0 !important;
  height: 100%;
}

.ProductListItem_cardWrapper__1U1W0 {
  padding: 0.25rem;
  max-width: 50%;
  min-width: 50%;
  height: 24rem;
}

.ProductListItem_cardWrapper__1U1W0.ProductListItem_modalCard__SlKL9 {
  min-width: 100%;
  max-width: 100%;
  height:23rem;
}

.ProductListItem_noClick__1osSL {
  pointer-events: none;
}

.ProductListItem_header__1rKRN {
  padding: 0.5rem 1.25rem;
}

.ProductListItem_infoContainer__1Jrh2 {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.ProductListItem_infoContainer__1Jrh2 h5{
  margin: 0;
  font-size: 1rem;
}

.ProductListItem_outOfStock__2zbqv {
  position: absolute;
  background-color: #21212023;
  height: 10rem;
  width: 100%;
}
.ProductListItem_outOfStockText__EZFqE{
  font-size: 2rem;
  font-weight: bold;
  color: #28a745;
  margin-top: 55px;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: #28a745;
  text-align: center;
}
.ProductListItem_infoContainerCentered__qvF42 {
  font-weight: 700;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ProductListItem_info__IIsXh {
  text-align: center;
  font-size: 0.625rem;
  width: 33%;
  min-width: 38px;
}

.ProductListItem_info__IIsXh p:first-child {
  margin-bottom: 0;
}

.ProductListItem_info__IIsXh p{
  width: 100%;
}

.ProductListItem_infoContainerCenteredGreen__3v0ja {
  font-weight: 700;
  display: flex;
  justify-content: center;
  color: #2eb82e;
  text-align: center;
  font-size: 0.875rem;
}

.ProductListItem_infoContainerCenteredGreen__3v0ja p{
  margin-top:-0.5rem;
  margin-bottom:0.25rem;
}

.ProductListItem_image__1aFNZ {
  width: unset !important;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.ProductListItem_imageContainer__2dEjE {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 8rem;
}

.ProductListItem_Indica__34lu0, .ProductListItem_Hybrid__2WjCC, .ProductListItem_Sativa__eIxJG {
  border: 1px solid transparent;
  color: white;
}

.ProductListItem_Indica__34lu0 {
  background-color: #bf00ff;
  border-color: #8600b3;
}

.ProductListItem_Hybrid__2WjCC {
  background-color: #70db70;
  border-color: #2eb82e;
}

.ProductListItem_Sativa__eIxJG {
  background-color: #e6e600;
  border-color: #999900;
  color: #4d4d00;
}

.ProductListItem_delivery__HpNJz {
  display: flex;
  align-items: center;
}

.ProductListItem_delivery__HpNJz > p {
  margin-bottom: 0;
  padding-right: 0.25rem;
}

.ProductListItem_brand__2H3et{
  margin-bottom: 0.25rem;
  text-align: center;
  font-weight: 400;
  font-size: 0.825rem;
}

.ProductListItem_title__3inf7 {
  justify-content: center;
  text-align: center;
  font-weight: 900;
  display: flex;
  font-size: 0.925rem;
}

@media (min-width: 768px) {
  /*.productCard {*/
  /*  min-width: 32.5% !important;*/
  /*}*/
  .ProductListItem_cardWrapper__1U1W0 {
    min-width: 33.3%;
    max-width: 33.3%;
    height:30rem;
  }

  .ProductListItem_cardWrapper__1U1W0.ProductListItem_modalCard__SlKL9 {
    min-width: 50%;
    max-width: 50%;
    height:30rem;
  }

  .ProductListItem_imageContainer__2dEjE {
    height: 12rem;
  }

  .ProductListItem_info__IIsXh {
    font-size: 0.825rem;
  }

  .ProductListItem_infoContainerCenteredGreen__3v0ja, .ProductListItem_brand__2H3et {
    font-size: inherit;
  }

  .ProductListItem_title__3inf7, .ProductListItem_infoContainer__1Jrh2 h5 {
    font-size: 1.25rem;
  }

  .ProductListItem_header__1rKRN{
    padding: 1.25rem;
  }
}

@media (min-width: 992px) {

  .ProductListItem_cardWrapper__1U1W0 {
    min-width: 25%;
    max-width: 25%;
    height: 27rem;
  }

  .ProductListItem_imageContainer__2dEjE {
    height: 9rem;
  }
}
.ProductList_cardDeck__1j3oX {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.Filters_buttonContainer__1xDp9 button{
  margin: 0.125rem;
}

.Filters_filterButton__3B-Dv {
  margin: 0.25rem;
}

.Filters_filterContainer__3ltJi {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap
}

@media(min-width: 768px){
  .Filters_buttonContainer__1xDp9 button:first-child{
    margin-left: 0rem;
  }
}
.FilterBadges_badgeContentContainer__1u3D7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.FilterBadges_labelSpan__4viaY {
  padding: 0 0 0 0.25rem;
}

.FilterBadges_badgeContainer__3ANMV {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.FilterBadges_badge__rFxdd {
  margin: 0 0.25rem
}
.Pagination_paginationContainer__NH5Ep {
    display: flex;
    justify-content: center;
    padding-top: 0.25rem;
}
.Products_container__2aCJw {
  padding-top: 1rem;
  padding-bottom: 5.125rem;
}
.Products_adcontainer__3_D0k{
  height: 150px
}
.Products_slider__UYZ5K {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-bottom: 30px;
  object-fit: contain;
}

.Products_slider__UYZ5K a.Products_previousButton__o-gqT, .Products_slider__UYZ5K a.Products_nextButton__3aptx {
  font-size: 22px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s linear;
  z-index: 1;
  color: #333;
  padding: 10px;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  /* prevent jump effect when scaling */
}

.Products_slider__UYZ5K a.Products_previousButton__o-gqT:not(.Products_disabled__1Mgzp):hover, .Products_slider__UYZ5K a.Products_nextButton__3aptx:not(.Products_disabled__1Mgzp):hover {
  transform: translateY(-50%) scale(1.25);
  cursor: pointer;
}

.Products_slider__UYZ5K a.Products_previousButton__o-gqT {
  left: 20px;
}

.Products_slider__UYZ5K a.Products_nextButton__3aptx {
  right: 20px;
}

.Products_slide__NcRjI {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  object-fit: contain;
}

.Products_slide__NcRjI.Products_hidden__EfIKQ {
  visibility: hidden;
}

.Products_slide__NcRjI.Products_previous__3SdaF {
  left: -100%;
}

.Products_slide__NcRjI.Products_current__YlSJC {
  left: 0;
}

.Products_slide__NcRjI.Products_next__1sNtu {
  left: 100%;
}

.Products_slide__NcRjI.Products_animateIn__2MYrB, .Products_slide__NcRjI.Products_animateOut__4jZUW {
  transition: all 2s ease;
}

.Products_slide__NcRjI.Products_animateIn__2MYrB.Products_previous__3SdaF, .Products_slide__NcRjI.Products_animateIn__2MYrB.Products_next__1sNtu {
  left: 0;
  visibility: visible;
}

.Products_slide__NcRjI.Products_animateOut__4jZUW.Products_previous__3SdaF {
  left: 100%;
}

.Products_slide__NcRjI.Products_animateOut__4jZUW.Products_next__1sNtu {
  left: -100%;
}
button {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	-webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
	        filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
	transition: background .5s ease, color .5s ease;
	border: none;
	background: #ffd800;
	border-radius: 30px;
	text-transform: uppercase;
	box-sizing: border-box;
	padding: 15px 40px;
	font-weight: 400;
	font-size: 13px;
	cursor: pointer;
}
	button:hover {
		color: #ffffff;
		background: #222222;
		-webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
		        filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
	}
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: #000000;
    z-index: 10;
  }
  header section {
    max-width: 1500px;
    height: 35px;
    margin: auto;
    padding: 20px;
    background: #ffd800;
  }
  
  header section a {
    float: right;
    padding: 3px 0;
  }
  
  .center {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .slider-content {
    color: white;
  }
  
  .slider-content:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
  }
  
  .Products_page__2jCUU {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 100px;
  }
  
  .Products_whiteText__3UhYh {
    color: white;
  }

  @media(min-width: 768px){
    .Products_slider__UYZ5K {
      height: 350px;
    }
    .Products_adcontainer__3_D0k{
      height: 350px
    }
  }
  @media(min-width: 992px){
    .Products_slider__UYZ5K {
      height: 350px;
    }
    .Products_adcontainer__3_D0k{
      height: 350px
    }
  }
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.Quantity_quantityContainer__2IxO4 {
  display: flex;
  justify-content: center;
  touch-action: manipulation;
}

.Quantity_quantityContainer__2IxO4 button:first-child{
  margin-right: 0.25rem;
}

.Quantity_quantityContainer__2IxO4 button:last-child{
  margin-left: 0.25rem;
}

.Quantity_quantityContainer__2IxO4 button{
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Quantity_quantityContainer__2IxO4 input {
  width: 6rem;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 0.25rem;
  border: 1px solid #ccc
}

.Quantity_hideIncrement__XIhkS {
  display: none !important;
}

.Product_Indica__3kxl-, .Product_Hybrid__1dgeC, .Product_Sativa__3pFYQ {
  border: 1px solid transparent;
  color: white;
}

.Product_Indica__3kxl- {
  background-color: #bf00ff;
  border-color: #8600b3;
}

.Product_brand__3nfNM{
  text-align: center;
  font-weight: 400;
}

.Product_strain__XrdWC{
  width:100%;
  margin-bottom: 0.5rem !important;
  text-align: center;
  font-weight: 900;
  border-bottom: 2px solid #cccccc;
}

.Product_Hybrid__1dgeC {
  background-color: #70db70;
  border-color: #2eb82e;
}

.Product_Sativa__3pFYQ {
  background-color: #e6e600;
  border-color: #999900;
  color: #4d4d00;
}

.Product_otherBadge__1qG1s {
  border: 1px solid #777
}

.Product_container__Gl_5P {
  padding: 0.5rem 0 3rem 0;
}

.Product_imageContainer__3jI8v, .Product_centeredColumns__23Yip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Product_image__7LTMt{
  width: 23rem;
  max-width: 100%;
}

.Product_price__345cF {
  text-align: center;
  font-size: 3rem;
  color: #28a745;
}

.Product_buttonContainer__33wXA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Product_topMargin__sryaL {
  margin-top: 1rem !important;
}

.Product_button__3cn-j {
  font-size: 1.25rem;
  max-width: 75%;
}

.Product_container__Gl_5P div {
  margin: 0;
}

.Product_secondDiv__1nr_l {
  margin-top: 1rem !important;
}

.Product_centeredColumns__23Yip {
  justify-content: space-around;
  flex-wrap: wrap;
}

.Product_badgeContainer__3fHPn {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.Product_badgeContainer__3fHPn h3:nth-child(odd){
  padding-right: 1rem;
}

.Product_productText__1ebBG {
  font-size: 1.25rem;
}

.Product_productText__1ebBG.Product_bold__7Eq_A {
  font-weight: bold;
}
.Product_divider__3hPqu{
  border: 1px solid gray;
  height: 2px;;
}
@media(min-width: 768px){
  .Product_secondDiv__1nr_l {
    margin-top: 0;
  }
}
@media(min-width: 992px){
  .Product_badgeContainer__3fHPn h3:nth-child(odd){
    padding: 0;
  }

  .Product_badgeContainer__3fHPn h3{
    padding: 0 0.5rem;
  }
}
.UploadImages_imageContainer__1g3wO{
  display: flex;
  justify-content: center;
  max-height: 16rem;
}

.UploadImages_image__2v1w8 {
  max-height: 12rem;
  width: auto;
  border: 1px solid #ccc;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.NewProduct_container__3GCta {
  padding-top: 1rem;
  padding-bottom: 5.125rem;
}
.List_container__2fcdA {
 padding-top: 1rem;
 padding-bottom: 5.125rem;
}

.List_listOfLists__3TVzo {
  position: fixed;
  top: 82px;
  left: 0;
  transition: left linear .35s;
  z-index: 1;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 90px;
}

.List_listOfLists__3TVzo.List_hideList__BT43g {
  left: -1500px;
}

.List_listPreview__1Bx4A {
  position: fixed;
  top: 82px;
  left: 0;
  transition: left linear .35s;
  z-index: 1;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 90px;
}

.List_listPreview__1Bx4A.List_hideList__BT43g{
  left: 2000px;
}

@media(min-width: 768px){
  .List_listOfLists__3TVzo, .List_listPreview__1Bx4A, .List_listOfLists__3TVzo.List_hideList__BT43g, .List_listPreview__1Bx4A.List_hideList__BT43g {
    position: relative;
    top: unset;
    left: unset;
    overflow: unset;
  }
}
.Under21_header__3vUsw {
  font-size: 1.9rem;
  text-align: center;
}

@media(min-width: 768px){
  .Under21_header__3vUsw{
    font-size: 2.5rem;
  }
}
* {
    box-sizing: border-box;
  }
  .Pricing_group__3X3KJ{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
  }

  .Pricing_cardBody__2Kk2l {
    background-color: white !important;
    color: black !important;
    padding: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .Pricing_cardHeader__2FCTE {
    text-align: center;
  }

  .Pricing_row__2ZPIn {
    width: 100%;
  }

  .Pricing_col__2FHMw{
    padding-bottom: 0.5rem;
  }

  .Pricing_content__3_L-n{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around
  }
  .Pricing_columns__111lk {
    float: left;
    width: 50%;
    padding: 8px;
  }
  .Pricing_featureItems__1uCrd{
    list-style-type: none;
    font-size: 2rem;
  }
  .Pricing_price__Bm13p {
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: 0.3s;
    border-radius: 0 0 0.25rem 0.25rem
  }
  .Pricing_image__1Ogy4{
    height: 200px;
  }
  .Pricing_items__2WApd{
    overflow: auto;
  }
  .Pricing_price__Bm13p .Pricing_header__tNhy6 {
    background-color: #111;
    color: white;
    font-size: 25px;
  }
  
  .Pricing_price__Bm13p li {
    border-bottom: 1px solid #eee;
    padding: 20px;
    text-align: center;
  }
  
  .Pricing_price__Bm13p .Pricing_grey__11ds3 {
    background-color: #eee;
    font-size: 20px;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  
  .Pricing_button__1Js7P {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
  }
  .Pricing_hideOnMobile__5_VqQ{
    display:none;
}
  @media(max-width: 768px) {
    .Pricing_columns__111lk {
      width: 100%;
    }   
  }
  @media(min-width: 992px){
    .Pricing_hideOnMobile__5_VqQ{
        display: block;
    }
  }
 
.Register_page__2ACwR{
    background-color: #000000;
    overflow: hidden; 
    width: 100%;
}
.Register_container__kwLe2{
    padding-top: 1rem;
    padding-bottom: 5.125rem;
}
.Register_registerCard__2kvzK{
    margin-top: 1rem;
    border-radius: 0.5rem;
    background-color:whitesmoke;
}
.Register_registrationButton__1Eshm{
    display: flex;
    justify-content: center;
}
.Payment_header__C5Hxe{
  border-bottom: solid 3px black;
}

.Payment_body__1qiyy {
  padding: 1rem 0;
}

.Payment_label__2q8su, .Payment_total__3HLyr {
  font-weight: bold;
}

.Payment_content__1ZXaV, .Payment_total__3HLyr {
  text-align: right;
}

.Payment_footer__3ikPy {
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  border-top: solid black 1px
}
.NewUser_actions__mVeIM{
    display: flex;
    justify-content: flex-end;
}
.NewUser_actions__mVeIM button{
    margin-left: 0.25rem;
}
.NewUser_image__1E8vD {
    max-height: 12rem;
    width: auto;
    border: 1px solid #ccc;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
.NewAd_actions__YI4RN{
    display: flex;
    justify-content: flex-end;
}
.NewAd_actions__YI4RN button{
    margin-left: 0.25rem;
}
.NewAd_image__2ivhM {
    max-height: 12rem;
    width: auto;
    border: 1px solid #ccc;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
.NewCategory_buttons__1UUsP{
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
}
.NewCategory_buttons__1UUsP button{
    margin-left: 0.25rem;
}
.Category_item__2nJN8{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 5px;
}
.Category_itemLabel__1Ghw_{
  pointer-events: none;
}
.Dashboards_topContainer__13I7R{
    margin-top: 10px;
    display: flex;  
    flex-wrap: wrap;
}
.Dashboards_header__1LWEA{
  font-size: 40px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.Dashboards_content__1DH3Y{
    width: 200px;
  }
  .Dashboards_chartTitle__ISme_{
    padding: 5px;
  }
  .Dashboards_tile__1l8XQ{
    display: flex;
    border: 1px solid #ccc;
    text-align: center;
    margin: 5px;
    width:250px;
    box-shadow: 0 10px 6px -6px #777;
  }
  .Dashboards_icon__1Dae9{
    height: 60px;
    padding: 5px;
    margin-top: 8px;
    margin-left: 15px;
  }
  .Dashboards_title__2NmuX{
    padding: 5px;
    margin-bottom: -10px;
  }
  .Dashboards_body__2EPsQ{
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .Dashboards_chartContainer__1CQOp{
    border: 1px solid #ccc;
    width: 48%;
    margin: 5px;
    box-shadow: 0 10px 6px -6px #777;
  }
  table { 
    width: 100%; 
    border-collapse: collapse; 
  }
  /* Zebra striping */
  tr:nth-of-type(odd) { 
    background: #eee; 
  }
  th { 
    background: #333; 
    color: white; 
    font-weight: bold; 
  }
  td, th { 
    padding: 6px; 
    border: 1px solid #ccc; 
    text-align: left; 
  }
  @media(max-width: 768px){
    .Dashboards_tile__1l8XQ{
      width: 48%;
    }
    .Dashboards_chartContainer__1CQOp{
      width: 100%;
    }
  }
  @media(max-width: 548px){
    .Dashboards_tile__1l8XQ{
      width: 100%;
    }
    .Dashboards_chartContainer__1CQOp{
      width: 100%;
    }
  }
  @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Brand"; }
	td:nth-of-type(2):before { content: "Available Amount"; }
}

.ResetPassword_container__1zgB7 { 
  padding-top: 1rem;
  padding-bottom: 5.125rem;
}
.OrderItem_card__3iT4F {
  margin-bottom: 0.25rem;
}

.OrderItem_container__CxAdV {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.OrderItem_infoContainer__3DJlq {
  text-align: center;
}

.OrderItem_column__1-wHQ.OrderItem_imageCol__3RYLg{
  display: flex;
  align-items: center;
}

.OrderItem_imageContainer__FML8a {
  max-width: 9rem;
  height: auto;
  overflow: hidden;
  max-height: 10rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrderItem_image__SMUPP {
  width: 100%;
  border-radius: 0.25rem;
}

.OrderItem_brand__2Vz2w {
  font-size: 1.25rem;
  font-weight: 200;
  margin-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.OrderItem_strain__3M7XE {
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.OrderItem_price__2o0SG{
  font-weight: 900;
}

.OrderItem_quantity__rQtPj {
  margin-bottom: 0;
}

.OrderItem_delivery__W5ho4 {
  font-size: 1.25rem;
  margin-bottom: 0.125rem;
}

.OrderItem_buttonContainer__2wdkC{
  display: flex;
}

.OrderItem_buttonContainer__2wdkC button, .OrderItem_buttonContainer__2wdkC a {
  margin-right: 0.25rem;
  margin-top: 0 !important;
}

.OrderItem_cartContainer__3V_3c {
  margin-right: 1rem
}

.OrderItem_quantity__rQtPj span{
  display: none;
}

@media(min-width: 768px){
  .OrderItem_container__CxAdV {
    flex-direction: row;
    justify-content: space-around;
  }

  .OrderItem_info__29bNz {
    text-align: left;
    width: 50%;
  }

  .OrderItem_infoContainer__3DJlq{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }

  .OrderItem_card__3iT4F{
    width: 100%;
  }

  .OrderItem_row__-nq_t {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .OrderItem_cardBody__2xVKF, .OrderItem_row__-nq_t, .OrderItem_column__1-wHQ {
    height: 100%;
    min-height: auto;
  }

  .OrderItem_price__2o0SG {
    font-size: 1.25rem;
  }
}

@media(min-width: 992px){

}
.Submit_total__1cn-Z {
  font-size: 2rem;
  font-weight: 900;
}

.Submit_totalContainer__1iWpK {
  display: flex;
  justify-content: space-around;
}
.Cart_container__2Rgym{
  padding-top: 1rem;
  padding-bottom: 5.125rem;
  display: flex;
  flex-wrap: wrap;
}

.Cart_row__3EXOV{ 
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.Cart_spinner__38TKO {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Cart_card__1piZO {
  width: 100%;
}

.Cart_footer__3yQ_j{
  display: flex;
  justify-content: center;
}

@media(min-width: 992px){
  .Cart_hideOnDesktop__3j04E {
    display: none;
  }
}
.Dashboards_topContainer__1JXPJ{
    margin-top: 10px;
    display: flex;  
    flex-wrap: wrap;
    justify-content: center;
}
.Dashboards_header__1c4YJ{
  font-size: 40px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.Dashboards_content__3Y9Hc{
    width: 200px;
  }
  .Dashboards_chartTitle__3BmpF{
    padding: 5px;
  }
  .Dashboards_tile__1EEhT{
    display: flex;
    border: 1px solid #ccc;
    text-align: center;
    margin: 5px;
    width:250px;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: .5rem;
  }
  .Dashboards_icon__2WDbV{
    height: 60px;
    padding: 5px;
    margin-top: 8px;
    margin-left: 15px;
  }
  .Dashboards_title__F23Zf{
    padding: 5px;
    margin-bottom: -10px;
  }
  .Dashboards_body__3bXc0{
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .Dashboards_chartContainer__1fonu{
    border: 1px solid #ccc;
    width: 48%;
    margin: 5px;
    box-shadow: 0 10px 6px -6px #777;
  }
  table { 
    width: 100%; 
    border-collapse: collapse; 
  }
  /* Zebra striping */
  tr:nth-of-type(odd) { 
    background: #eee; 
  }
  th { 
    background: #333; 
    color: white; 
    font-weight: bold; 
  }
  td, th { 
    padding: 6px; 
    border: 1px solid #ccc; 
    text-align: left; 
  }
  @media(max-width: 768px){
    .Dashboards_tile__1EEhT{
      width: 48%;
    }
    .Dashboards_chartContainer__1fonu{
      width: 100%;
    }
  }
  @media(max-width: 548px){
    .Dashboards_tile__1EEhT{
      width: 100%;
    }
    .Dashboards_chartContainer__1fonu{
      width: 100%;
    }
  }
  @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Brand"; }
	td:nth-of-type(2):before { content: "Available Amount"; }
}

.Product_Indica__1rMYR, .Product_Hybrid__3le8i, .Product_Sativa__1DcK5 {
  border: 1px solid transparent;
  color: white;
}

.Product_Indica__1rMYR {
  background-color: #bf00ff;
  border-color: #8600b3;
}

.Product_brand__2c4lq{
  text-align: center;
  font-weight: 400;
}

.Product_strain__2wiQ0{
  width:100%;
  margin-bottom: 0.5rem !important;
  text-align: center;
  font-weight: 900;
  border-bottom: 2px solid #cccccc;
}

.Product_Hybrid__3le8i {
  background-color: #70db70;
  border-color: #2eb82e;
}

.Product_Sativa__1DcK5 {
  background-color: #e6e600;
  border-color: #999900;
  color: #4d4d00;
}

.Product_otherBadge__3gxEe {
  border: 1px solid #777
}

.Product_container__ovWWj {
  padding: 0.5rem 0 3rem 0;
}

.Product_imageContainer__1jH9r, .Product_centeredColumns__bW1Lt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Product_image__1BTVb{
  width: 23rem;
  max-width: 100%;
}

.Product_price__1cxhT {
  text-align: center;
  font-size: 3rem;
  color: #28a745;
}

.Product_buttonContainer__1__2t {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Product_topMargin__3A78d {
  margin-top: 1rem !important;
}

.Product_button__2v_7j {
  font-size: 1.25rem;
  max-width: 75%;
}

.Product_container__ovWWj div {
  margin: 0;
}

.Product_secondDiv__V3uio {
  margin-top: 1rem !important;
}

.Product_centeredColumns__bW1Lt {
  justify-content: space-around;
  flex-wrap: wrap;
}

.Product_badgeContainer__2yPQ3 {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.Product_badgeContainer__2yPQ3 h3:nth-child(odd){
  padding-right: 1rem;
}

.Product_productText__3a9Ot {
  font-size: 1.25rem;
}

.Product_productText__3a9Ot.Product_bold__31VMp {
  font-weight: bold;
}
.Product_divider__1Fadm{
  border: 1px solid gray;
  height: 2px;;
}
@media(min-width: 768px){
  .Product_secondDiv__V3uio {
    margin-top: 0;
  }
}
@media(min-width: 992px){
  .Product_badgeContainer__2yPQ3 h3:nth-child(odd){
    padding: 0;
  }

  .Product_badgeContainer__2yPQ3 h3{
    padding: 0 0.5rem;
  }
}


.Navigation_navbar__fp9kn {
  padding: 0;
  height: 66px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #306a39;
}

.Navigation_navbarBottom__byVnl {
  padding-top: 20px;
  height: 40px;
  background-color: whitesmoke;
  color: black;
  text-decoration: none;
  border-top: 1px solid #306a39;
}

.Navigation_navbarBottom__byVnl p {
  cursor: pointer;
}

.Navigation_maxHeight__1jmVa {
  height: 100%
}

.Navigation_link__2oNc7 {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  padding: 1rem !important;
  font-weight: bold;
  color: #306a39 !important;
  height: 100%;
}

.Navigation_active__e85wI {
  background-color: #306a39 !important;
  color: whitesmoke !important
}

.Navigation_collapse__10YOD {
  position: absolute;
  top: 66px;
  width: 100%;
  left: 0;
  z-index: 1;
}

.Navigation_container__oxpF2 {
  padding: 0 1rem !important; 
  height: 100%;
  justify-content: space-around;
  display: flex;
}

.Navigation_dropdown__1mJuV {
  display: none;
}

.Navigation_logo__mzPC1{
  height: 33px;
}

.Navigation_showOnDesktop__31-I0 {
  display: none;
}

.Navigation_countBadge__1a4L4 {
  position: absolute;
  top: 10px;
  margin-left: -10px;
  padding: 0 .5rem;
  color: whitesmoke;
  background-color: #306a39;
  border-radius: 100%;
  border: 2px solid whitesmoke
}

.Navigation_cartContainer__3fDPw {
  margin-right: 0.75rem
}

@media(min-width: 768px){
  .Navigation_logo__mzPC1{
    height: 40px;
  }
}

@media(min-width: 992px) {
  .Navigation_hideOnDesktop__3L6Jn{
    display: none
  }

  .Navigation_showOnDesktop__31-I0 {
    display: block;
  }

  .Navigation_link__2oNc7 {
    border: none;
    border-bottom: 5px solid transparent;
    background-color: #f5f5f5 !important;
    color: #306a39 !important;
    display: none;
  }

  .Navigation_active__e85wI{
    color: #306a39 !important;
    border-bottom: 5px solid #306a39 !important;
  }

  .Navigation_collapse__10YOD {
    position: relative;
    top: unset;
  }

  .Navigation_container__oxpF2 {
    padding: 0;
  }

  .Navigation_userLinks__2qeud {
    display: none;
  }

  .Navigation_permanent__1A-4T {
    display: block !important;
  }

  .Navigation_dropdown__1mJuV {
    display: flex;
    margin-top: 4px;
    border: 5px solid transparent;
  }

  .Navigation_dropdown__1mJuV a:first-of-type{
    margin-top: -19px;
  }

  .Navigation_dropdown__1mJuV a, .Navigation_dropdown__1mJuV div {
    color: #306a39 !important;
    background-color: #f5f5f5 !important;
  }

  .Navigation_dropdown__1mJuV div div{
    border-color: #306a39 !important;
  }

  .Navigation_dropdown__1mJuV {
    align-items: center;
    height: 68px;
    font-weight: bold;
    color: #306a39 !important;
  }

  .Navigation_cart__3Z6BO {
    margin-left: 3rem
  }
}
.Search_searchBar__2ZdT3 {
  width: 100%;
}

.Search_searchBarContainer__31ZwX {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Search_formGroup__21YCK{
  margin-bottom: 0.25rem;
}

.Search_button__2u8of{
  width: 100%;
}

@media(min-width: 576px) {
  .Search_searchBar__2ZdT3{
    width: 80%;
  }

  .Search_formGroup__21YCK{
    margin-bottom: 1rem;
  }
}

@media(min-width: 992px) {
  .Search_searchBar__2ZdT3{
    width: 60%;
  }
}
.OrderItemCart_card__13Zvw {
  margin-bottom: 0.25rem;
}

.OrderItemCart_container__1urD- {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.OrderItemCart_infoContainer__ojAEh {
  text-align: center;
}

.OrderItemCart_column__1OpQC.OrderItemCart_imageCol__7fJqz{
  display: flex;
  align-items: center;
}

.OrderItemCart_imageContainer__2vegY {
  max-width: 9rem;
  height: auto;
  overflow: hidden;
  max-height: 10rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrderItemCart_image__qeA_W {
  width: 100%;
  border-radius: 0.25rem;
}

.OrderItemCart_brand__1OkKs {
  font-size: 1.25rem;
  font-weight: 200;
  margin-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.OrderItemCart_strain__3QC0F {
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.OrderItemCart_price__2SEXd{
  font-weight: 900;
}

.OrderItemCart_quantity__2pmLo {
  margin-bottom: 0;
}

.OrderItemCart_delivery__Be3Xk {
  font-size: 1.25rem;
  margin-bottom: 0.125rem;
}

.OrderItemCart_buttonContainer__tBFTG{
  display: flex;
}

.OrderItemCart_buttonContainer__tBFTG button, .OrderItemCart_buttonContainer__tBFTG a {
  margin-right: 0.25rem;
  margin-top: 0 !important;
}

.OrderItemCart_cartContainer__3qyVp {
  margin-right: 1rem
}

.OrderItemCart_quantity__2pmLo span{
  display: none;
}

@media(min-width: 768px){
  .OrderItemCart_container__1urD- {
    flex-direction: row;
    justify-content: space-around;
  }

  .OrderItemCart_info__1OydG {
    text-align: left;
    width: 50%;
  }

  .OrderItemCart_infoContainer__ojAEh{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }

  .OrderItemCart_card__13Zvw{
    width: 100%;
  }

  .OrderItemCart_row__3AyBl {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .OrderItemCart_cardBody__f4E3E, .OrderItemCart_row__3AyBl, .OrderItemCart_column__1OpQC {
    height: 100%;
    min-height: auto;
  }

  .OrderItemCart_price__2SEXd {
    font-size: 1.25rem;
  }
}

@media(min-width: 992px){

}
.OrderItemMail_card__1bsPS {
  margin-bottom: 0.25rem;
  height: 220px;
}

.OrderItemMail_cardBody__Cc2-3, .OrderItemMail_row__1XGij, .OrderItemMail_column__VgAk2 {
  height: 90%;
}

.OrderItemMail_column__VgAk2.OrderItemMail_imageCol__11LKu{
  display: flex;
  align-items: center;
}

.OrderItemMail_imageContainer__1sbL9 {
  max-width: 9rem;
  height: auto;
  overflow: hidden;
  max-height: 10rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrderItemMail_image__2RyVi {
  width: 100%;
  border-radius: 0.25rem;
}

.OrderItemMail_brand__oQlp- {
  font-size: 1.25rem;
  font-weight: 200;
  margin-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.OrderItemMail_strain__3IFrV {
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.OrderItemMail_price__1NaLT{
  font-weight: 900;
}

.OrderItemMail_quantity__3co6D {
  margin-bottom: 0;
}

.OrderItemMail_delivery__ycuhY {
  font-size: 1.25rem;
  margin-bottom: 0.125rem;
}

.OrderItemMail_buttonContainer__3-jKI{
  display: flex;
}

.OrderItemMail_buttonContainer__3-jKI button, .OrderItemMail_buttonContainer__3-jKI a {
  margin-right: 0.25rem;
  margin-top: 0 !important;
}

.OrderItemMail_info__2au6s {
  display: flex;
  padding-right: 0.5rem;
  align-items: center;
}

.OrderItemMail_info__2au6s div{
  display: flex;
  align-items: center;
  padding-right: 1rem
}

.OrderItemMail_info__2au6s div p:first-child{
  padding-right: 0.25rem;
} 

.OrderItemMail_info__2au6s div p, .OrderItemMail_info__2au6s p {
  margin-bottom: 0;
}

.OrderItemMail_hideOnMobile__HHtgC {
  display: None;
}

.OrderItemMail_cartContainer__3qxUR {
  margin-right: 1rem
}

@media(min-width: 768px){
  .OrderItemMail_card__1bsPS{
    height: 184px;
    width: 100%;
  }

  .OrderItemMail_cardBody__Cc2-3, .OrderItemMail_row__1XGij, .OrderItemMail_column__VgAk2 {
    height: 100%;
  }

  .OrderItemMail_info__2au6s {
    display: block;
  }

  .OrderItemMail_info__2au6s div{
    display: block;
    text-align: right;
  }

  .OrderItemMail_showOnMobile__2v_LF {
    display: none;
  }

  .OrderItemMail_hideOnMobile__HHtgC {
    display: flex;
  }

  .OrderItemMail_hideOnMobile__HHtgC.OrderItemMail_info__2au6s {
    flex-direction: column;
    align-items: flex-end;
  }

  .OrderItemMail_price__1NaLT {
    padding: 1rem;
    font-size: 1.25rem;
  }
}

@media(min-width: 992px){

}
.OrderItemHistory_headerTitle__2T0X9 {
  font-weight: 700;
  margin-bottom: 0;
}

.OrderItemHistory_card__28Pj0 {
  margin: 0.5rem 0;
}

.OrderItemHistory_body__1idjh {
  border-bottom: 1px solid rgba(0,0,0,0.125);
}

.OrderItemHistory_body__1idjh:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.OrderItemHistory_orderNumber__2gU12 {
  margin-bottom: 0;
}

.OrderItemHistory_imageContainer__3kWJu {
  max-height: 10rem;
  max-width: 10rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  margin: 0.5rem;
}

.OrderItemHistory_imageColumn__AXGSV {
  justify-content: center;
  display: flex;
}

.OrderItemHistory_image__3wnBA {
  max-width: 10rem;
  height: auto;
}

.OrderItemHistory_numberContainer__2tkzh {
  display: flex;
  justify-content: space-around;
}

.OrderItemHistory_quantity__1egni, .OrderItemHistory_price__2yJv3 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.OrderItemHistory_price__2yJv3, .OrderItemHistory_total__66UBS {
  font-weight: 700;
  color: #990000;
}

.OrderItemHistory_owner__3rpek {
  margin-bottom: 0.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.OrderItemHistory_description__2uGmm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.OrderItemHistory_productInfo__36cHq {
  padding: 1rem 0.5rem;
  border-top: 1px solid rgba(0,0,0,0.05)
}

.OrderItemHistory_productInfo__36cHq:first-child{
  border-top: none
}

.OrderItemHistory_row__1THzH {
  padding: 0.25rem;
}

.OrderItemHistory_buttonContainer__3OFuO {
  margin-bottom: 1rem;
}

.OrderItemHistory_button__3hvjw {
  width: 100%
}

.OrderItemHistory_showAddress__2WDHb {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.OrderItemHistory_hideAddress__2KRV4, .OrderItemHistory_hideReason__SoNAA {
  display: none;
}

@media(min-width: 768px) {
  .OrderItemHistory_imageContainer__3kWJu {
    max-width: 6rem;
    max-height: 6rem;
  }

  .OrderItemHistory_buttonContainer__3OFuO {
    margin-bottom: 0;
  }

  .OrderItemHistory_image__3wnBA {
    max-width: 6rem;
  }

  .OrderItemHistory_quantity__1egni, .OrderItemHistory_price__2yJv3, .OrderItemHistory_owner__3rpek {
    justify-content: flex-start;
  }

  .OrderItemHistory_quantity__1egni {
    margin-bottom: 0.5rem;
  }

  .OrderItemHistory_description__2uGmm {
    display: block;
    text-align: left;
  }

  .OrderItemHistory_textContainer__1Ep3d {
    padding: 1rem 0
  }

  .OrderItemHistory_controls__1KZ0N {
    padding: 1rem;
  }

  .OrderItemHistory_productInfo__36cHq {
    padding-bottom: 0;
  }
}

@media(min-width: 992px){
  .OrderItemHistory_header__2PxED {
    padding-bottom: 0;
  }

  .OrderItemHistory_showAddress__2WDHb {
    padding-top:0
  }
}


.ProductListItem_productCard__mFLyh {
  margin: 3px !important;
  background-color: #fcfcfc !important;
}

.ProductListItem_noClick__v2fZo {
  pointer-events: none;
}

.ProductListItem_infoContainer__28OeG {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}
.ProductListItem_outOfStock__NKKPn {
  position: absolute;    
  background-color: #21212023;
  height: 10rem;
  width: 100%;
}
.ProductListItem_outOfStockText__3qdyZ{
  font-size: 2rem;
  font-weight: bold;
  color: #28a745;
  margin-top: 55px;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: #28a745;
  text-align: center;
}
.ProductListItem_infoContainerCentered__PD7nO {
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.ProductListItem_infoContainerCenteredGreen__2oUeY {
  font-weight: 700;
  display: flex;
  justify-content: center;
  color: #2eb82e;
}
.ProductListItem_image__rhqED {
  width: unset !important;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.ProductListItem_imageContainer__q76VA {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 12rem;
}

.ProductListItem_Indica__2HqPL, .ProductListItem_Hybrid__3cx3b, .ProductListItem_Sativa__3WPaV {
  border: 1px solid transparent;
  color: white;
}

.ProductListItem_Indica__2HqPL {
  background-color: #bf00ff;
  border-color: #8600b3;
}

.ProductListItem_Hybrid__3cx3b {
  background-color: #70db70;
  border-color: #2eb82e;
}

.ProductListItem_Sativa__3WPaV {
  background-color: #e6e600;
  border-color: #999900;
  color: #4d4d00;
}

.ProductListItem_delivery__279Jx {
  display: flex;
  align-items: center;
}

.ProductListItem_delivery__279Jx > p {
  margin-bottom: 0;
  padding-right: 0.25rem;
}

.ProductListItem_brand__p-FbM{
  margin-bottom: 0.25rem;
  text-align: center;
  font-weight: 400;
}

@media (min-width: 576px){
  .ProductListItem_productCard__mFLyh {
    min-width: 48.89% !important;
  }
}

@media (min-width: 768px) {
  .ProductListItem_productCard__mFLyh {
    min-width: 32.5% !important;
  }
}

@media (min-width: 992px) {
  .ProductListItem_productCard__mFLyh {
    min-width: 24% !important;
    max-width: 24%;
  }

  .ProductListItem_productCard__mFLyh.ProductListItem_modalCard__3bPKH {
    max-width: 50%;
    min-width: 50% !important;
  }

  .ProductListItem_imageContainer__q76VA {
    height: 9rem;
  }
}

@media (min-width: 1200px) {
  .ProductListItem_productCard__mFLyh {
    min-width: 24.47% !important;
    max-width: 24.47%;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.Quantity_quantityContainer__2cW1w {
  display: flex;
  justify-content: center;
  touch-action: manipulation;
}

.Quantity_quantityContainer__2cW1w button:first-child{
  margin-right: 0.25rem;
}

.Quantity_quantityContainer__2cW1w button:last-child{
  margin-left: 0.25rem;
}

.Quantity_quantityContainer__2cW1w button{
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Quantity_quantityContainer__2cW1w input {
  width: 6rem;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 0.25rem;
  border: 1px solid #ccc
}

.Quantity_hideIncrement__5FmHw {
  display: none !important;
}

.Login_loginButton__Yc_Wa {
  display: flex;
  justify-content: flex-end;
}

.Login_register__VmI-z{
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid #ccc;
}
* {
    box-sizing: border-box;
  }
  .Pricing_group__2S_nJ{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
  }

  .Pricing_cardBody__l4Isf {
    background-color: white !important;
    color: black !important;
    padding: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .Pricing_cardHeader__1OVHF {
    text-align: center;
  }

  .Pricing_row__1Ysje {
    width: 100%;
  }

  .Pricing_col__1RI8O{
    padding-bottom: 0.5rem;
  }

  .Pricing_content__1-py4{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around
  }
  .Pricing_columns__3uV35 {
    float: left;
    width: 50%;
    padding: 8px;
  }
  .Pricing_featureItems__2d_BY{
    list-style-type: none;
    font-size: 2rem;
  }
  .Pricing_price__25tb_ {
    list-style-type: none;
    margin: 0;
    padding: 0;
    transition: 0.3s;
    border-radius: 0 0 0.25rem 0.25rem
  }
  .Pricing_image__3dKu_{
    height: 200px;
  }
  .Pricing_items__23sWR{
    overflow: auto;
  }
  .Pricing_price__25tb_ .Pricing_header__v_F-P {
    background-color: #111;
    color: white;
    font-size: 25px;
  }
  
  .Pricing_price__25tb_ li {
    border-bottom: 1px solid #eee;
    padding: 20px;
    text-align: center;
  }
  
  .Pricing_price__25tb_ .Pricing_grey__2XYdc {
    background-color: #eee;
    font-size: 20px;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  
  .Pricing_button__8YeJA {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
  }
  .Pricing_hideOnMobile__-oF_M{
    display:none;
}
  @media(max-width: 768px) {
    .Pricing_columns__3uV35 {
      width: 100%;
    }   
  }
  @media(min-width: 992px){
    .Pricing_hideOnMobile__-oF_M{
        display: block;
    }
  }
 
.Register_page__MPjdx{
    background-color: #000000;
    overflow: hidden; 
    width: 100%;
}
.Register_container__1PoIX{
    padding-top: 1rem;
    padding-bottom: 5.125rem;
}
.Register_registerCard__1tYk2{
    margin-top: 1rem;
    border-radius: 0.5rem;
    background-color:whitesmoke;
}
.Register_registrationButton__20yJB{
    display: flex;
    justify-content: center;
}
.Dashboards_topContainer__12l60{
    margin-top: 10px;
    display: flex;  
    flex-wrap: wrap;
    justify-content: center;
}
.Dashboards_header__3wpmD{
  font-size: 40px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.Dashboards_content__2ctv7{
    width: 200px;
  }
  .Dashboards_chartTitle__2dTbV{
    padding: 5px;
  }
  .Dashboards_tile__2tsYd{
    display: flex;
    border: 1px solid #ccc;
    text-align: center;
    margin: 5px;
    width:250px;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: .5rem;
  }
  .Dashboards_icon__2JNJg{
    height: 60px;
    padding: 5px;
    margin-top: 8px;
    margin-left: 15px;
  }
  .Dashboards_title__2jqCF{
    padding: 5px;
    margin-bottom: -10px;
  }
  .Dashboards_body__B6dXU{
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .Dashboards_chartContainer__2Fjaf{
    border: 1px solid #ccc;
    width: 48%;
    margin: 5px;
    box-shadow: 0 10px 6px -6px #777;
  }
  table { 
    width: 100%; 
    border-collapse: collapse; 
  }
  /* Zebra striping */
  tr:nth-of-type(odd) { 
    background: #eee; 
  }
  th { 
    background: #333; 
    color: white; 
    font-weight: bold; 
  }
  td, th { 
    padding: 6px; 
    border: 1px solid #ccc; 
    text-align: left; 
  }
  @media(max-width: 768px){
    .Dashboards_tile__2tsYd{
      width: 48%;
    }
    .Dashboards_chartContainer__2Fjaf{
      width: 100%;
    }
  }
  @media(max-width: 548px){
    .Dashboards_tile__2tsYd{
      width: 100%;
    }
    .Dashboards_chartContainer__2Fjaf{
      width: 100%;
    }
  }
  @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Brand"; }
	td:nth-of-type(2):before { content: "Available Amount"; }
}

.OrderItem_headerTitle__bjQsL {
  font-weight: 700;
  margin-bottom: 0;
}

.OrderItem_card__3k8g1 {
  margin: 0.5rem 0;
}

.OrderItem_body__2XNWU {
  border-bottom: 1px solid rgba(0,0,0,0.125);
}

.OrderItem_body__2XNWU:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.OrderItem_orderNumber__3W9DW {
  margin-bottom: 0;
}

.OrderItem_imageContainer__2OZPC {
  max-height: 10rem;
  max-width: 10rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  margin: 0.5rem;
}

.OrderItem_imageColumn__3hzOC {
  justify-content: center;
  display: flex;
}

.OrderItem_image__1hTnK {
  max-width: 10rem;
  height: auto;
}

.OrderItem_numberContainer__KtSd1 {
  display: flex;
  justify-content: space-around;
}

.OrderItem_quantity__DFVCV, .OrderItem_price__1D542 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.OrderItem_price__1D542, .OrderItem_total__3AnMJ {
  font-weight: 700;
  color: #990000;
}

.OrderItem_owner__1g0mm {
  margin-bottom: 0.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.OrderItem_description__wEmVm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.OrderItem_productInfo__1Ukd7 {
  padding: 1rem 0.5rem;
  border-top: 1px solid rgba(0,0,0,0.05)
}

.OrderItem_productInfo__1Ukd7:first-child{
  border-top: none
}

.OrderItem_row__cNDqc {
  padding: 0.25rem;
}

.OrderItem_buttonContainer__1dt4P {
  margin-bottom: 1rem;
}

.OrderItem_button__4Q59J {
  width: 100%
}

.OrderItem_showAddress__38s5r {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.OrderItem_hideAddress__VeWJ7, .OrderItem_hideReason__2QoQd {
  display: none;
}

@media(min-width: 768px) {
  .OrderItem_imageContainer__2OZPC {
    max-width: 6rem;
    max-height: 6rem;
  }

  .OrderItem_buttonContainer__1dt4P {
    margin-bottom: 0;
  }

  .OrderItem_image__1hTnK {
    max-width: 6rem;
  }

  .OrderItem_quantity__DFVCV, .OrderItem_price__1D542, .OrderItem_owner__1g0mm {
    justify-content: flex-start;
  }

  .OrderItem_quantity__DFVCV {
    margin-bottom: 0.5rem;
  }

  .OrderItem_description__wEmVm {
    display: block;
    text-align: left;
  }

  .OrderItem_textContainer__1RHD5 {
    padding: 1rem 0
  }

  .OrderItem_controls__2VKwS {
    padding: 1rem;
  }

  .OrderItem_productInfo__1Ukd7 {
    padding-bottom: 0;
  }
}

@media(min-width: 992px){
  .OrderItem_header__jTqhp {
    padding-bottom: 0;
  }

  .OrderItem_showAddress__38s5r {
    padding-top:0
  }
}


.OrderHistory_container__2T_xY {
  padding-top: 1rem;
  padding-bottom: 3.5rem;
}

.OrderHistory_hide__3c5vi {
  display: none;
}

.OrderHistory_modalBodyCenter__20Z6u {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AccountInfoForm_buttonContainer__2Y7Ak {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.125);
}

.AccountInfoForm_buttonContainer__2Y7Ak button {
  margin-left: 0;
  width: 100%;
  margin-bottom: 1rem;
}

@media(min-width: 576px){
  .AccountInfoForm_buttonContainer__2Y7Ak button {
    margin-left: 0.5rem;
    width: auto;
  }
}
.PasswordForm_container__1Ksfz {
  margin-top: 1rem;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.125);
}

.PasswordForm_buttonContainer__1Dbdi {
  display: flex;
  justify-content: flex-end;
}

.PasswordForm_buttonContainer__1Dbdi button {
  margin-left: 0;
  width: 100%;
  margin-bottom: 1rem;
}

@media(min-width: 576px){
  .PasswordForm_buttonContainer__1Dbdi button {
    margin-left: 0.5rem;
    width: auto;
  }
}
.ManageSubscription_buttonContainer__1IMm9 {
  display: flex;
  justify-content: center;
  margin-top: 1rem
}

.ManageSubscription_buttonContainer__1IMm9 button {
  margin-top: 0.5rem
}

.ManageSubscription_buttonContainer__1IMm9 div:first-child button{
  margin-top: 0;
}

.ManageSubscription_container__2sqWM {
  margin-top: 1rem;
}

@media(min-width: 768px){
  .ManageSubscription_buttonContainer__1IMm9 button {
    margin-top: 0;
  }
}
.Account_container__2BTwc {
  padding-top: 1rem;
  padding-bottom: 5.125rem;
}

.Account_buttonContainer__lhaDF {
  display: flex;
  justify-content: flex-end;
}

.Account_buttonContainer__lhaDF button {
  margin-left: 0.5rem;
}
.bg-dark {
  background-color: black !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(48, 106, 57)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler, .navbar-toggler {
  border-color: #306a39 !important;
  padding: .25rem .5rem !important;
} 

.navbar-toggler-icon:hover,  .navbar-toggler-icon:focus {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(245, 245, 245)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  border-color: #306a39 !important;
  background-color: #306a39 !important;
}

.page-item .page-link{
  border-color: rgba(0,0,0,0.125) !important;
  color: #306a39 !important;
  background-color: #fcfcfc;
}

.page-item.disabled .page-link{
  border-color: rgba(0,0,0,0.125) !important;
  color: #306a39 !important;
  background-color: #d9d9d9;
}

.page-item.active .page-link{
  border-color: #rgba(0,0,0,0.125) !important;
  background-color: #306a39 !important;
  color: #fcfcfc !important;
}

.dropdown-menu {
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: black;
  width: 100%;
}

.dropdown-toggle{
  padding-left: 1rem !important;
}

.UploadImages_imageContainer__M8Enh div div canvas, .UploadImages_imageContainer__M8Enh div div div {
  max-height: 14rem;
  max-width: 10.8rem;
  margin: 1rem;
  border-radius: 0.25rem
}

.TestResults_pdf__30jEW div canvas{
  transform: scale(0.5);
  border: 2px solid #ccc;
  margin-top: -195px;
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations {
  display: none !important;
}

.TestResults_pdf__30jEW div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__input-container input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-clip: padding-box;
  line-height: 1.5;
  font-weight: 400;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.modal-footer {
  display: flex;
  flex-direction: column;
}

.modal-footer button {
  width: 100%;
}

@media(min-width: 768px){
  .TestResults_pdf__30jEW div canvas{
    transform: scale(0.7);
    margin-top: -120px;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
  }
  
  .modal-footer button {
    width: auto;
  }
}

@media(min-width: 1024px){
  .TestResults_pdf__30jEW div canvas{
    transform: scale(1);
    margin-top: -5px;
  }
}
