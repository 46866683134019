.container {
  padding-top: 1rem;
  padding-bottom: 3.5rem;
}

.hide {
  display: none;
}

.modalBodyCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
