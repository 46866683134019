.headerTitle {
  font-weight: 700;
  margin-bottom: 0;
}

.card {
  margin: 0.5rem 0;
}

.body {
  border-bottom: 1px solid rgba(0,0,0,0.125);
}

.body:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.orderNumber {
  margin-bottom: 0;
}

.imageContainer {
  max-height: 10rem;
  max-width: 10rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex;
  margin: 0.5rem;
}

.imageColumn {
  justify-content: center;
  display: flex;
}

.image {
  max-width: 10rem;
  height: auto;
}

.numberContainer {
  display: flex;
  justify-content: space-around;
}

.quantity, .price {
  display: flex;
  justify-content: center;
  text-align: center;
}

.price, .total {
  font-weight: 700;
  color: #990000;
}

.owner {
  margin-bottom: 0.5rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.productInfo {
  padding: 1rem 0.5rem;
  border-top: 1px solid rgba(0,0,0,0.05)
}

.productInfo:first-child{
  border-top: none
}

.row {
  padding: 0.25rem;
}

.buttonContainer {
  margin-bottom: 1rem;
}

.button {
  width: 100%
}

.showAddress {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.hideAddress, .hideReason {
  display: none;
}

@media(min-width: 768px) {
  .imageContainer {
    max-width: 6rem;
    max-height: 6rem;
  }

  .buttonContainer {
    margin-bottom: 0;
  }

  .image {
    max-width: 6rem;
  }

  .quantity, .price, .owner {
    justify-content: flex-start;
  }

  .quantity {
    margin-bottom: 0.5rem;
  }

  .description {
    display: block;
    text-align: left;
  }

  .textContainer {
    padding: 1rem 0
  }

  .controls {
    padding: 1rem;
  }

  .productInfo {
    padding-bottom: 0;
  }
}

@media(min-width: 992px){
  .header {
    padding-bottom: 0;
  }

  .showAddress {
    padding-top:0
  }
}

