@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

.container {
  padding-top: 1rem;
  padding-bottom: 5.125rem;
}
.adcontainer{
  height: 150px
}
.slider {
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-bottom: 30px;
  object-fit: contain;
}

.slider a.previousButton, .slider a.nextButton {
  font-size: 22px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all .3s linear;
  z-index: 1;
  color: #333;
  padding: 10px;
  text-decoration: none;
  backface-visibility: hidden;
  /* prevent jump effect when scaling */
}

.slider a.previousButton:not(.disabled):hover, .slider a.nextButton:not(.disabled):hover {
  transform: translateY(-50%) scale(1.25);
  cursor: pointer;
}

.slider a.previousButton {
  left: 20px;
}

.slider a.nextButton {
  right: 20px;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  object-fit: contain;
}

.slide.hidden {
  visibility: hidden;
}

.slide.previous {
  left: -100%;
}

.slide.current {
  left: 0;
}

.slide.next {
  left: 100%;
}

.slide.animateIn, .slide.animateOut {
  transition: all 2s ease;
}

.slide.animateIn.previous, .slide.animateIn.next {
  left: 0;
  visibility: visible;
}

.slide.animateOut.previous {
  left: 100%;
}

.slide.animateOut.next {
  left: -100%;
}
button {
	appearance: none;
	filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
	transition: background .5s ease, color .5s ease;
	border: none;
	background: #ffd800;
	border-radius: 30px;
	text-transform: uppercase;
	box-sizing: border-box;
	padding: 15px 40px;
	font-weight: 400;
	font-size: 13px;
	cursor: pointer;
}
	button:hover {
		color: #ffffff;
		background: #222222;
		filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
	}
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    background: #000000;
    z-index: 10;
  }
  header section {
    max-width: 1500px;
    height: 35px;
    margin: auto;
    padding: 20px;
    background: #ffd800;
  }
  
  header section a {
    float: right;
    padding: 3px 0;
  }
  
  :global(.center) {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  :global(.slider-content) {
    color: white;
  }
  
  :global(.slider-content):before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
  }
  
  .page {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 100px;
  }
  
  .whiteText {
    color: white;
  }

  @media(min-width: 768px){
    .slider {
      height: 350px;
    }
    .adcontainer{
      height: 350px
    }
  }
  @media(min-width: 992px){
    .slider {
      height: 350px;
    }
    .adcontainer{
      height: 350px
    }
  }