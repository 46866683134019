.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 1rem
}

.buttonContainer button {
  margin-top: 0.5rem
}

.buttonContainer div:first-child button{
  margin-top: 0;
}

.container {
  margin-top: 1rem;
}

@media(min-width: 768px){
  .buttonContainer button {
    margin-top: 0;
  }
}