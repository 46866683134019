.header{
  border-bottom: solid 3px black;
}

.body {
  padding: 1rem 0;
}

.label, .total {
  font-weight: bold;
}

.content, .total {
  text-align: right;
}

.footer {
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  border-top: solid black 1px
}