.buttonContainer button{
  margin: 0.125rem;
}

.filterButton {
  margin: 0.25rem;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap
}

@media(min-width: 768px){
  .buttonContainer button:first-child{
    margin-left: 0rem;
  }
}