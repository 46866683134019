.Indica, .Hybrid, .Sativa {
  border: 1px solid transparent;
  color: white;
}

.Indica {
  background-color: #bf00ff;
  border-color: #8600b3;
}

.brand{
  text-align: center;
  font-weight: 400;
}

.strain{
  width:100%;
  margin-bottom: 0.5rem !important;
  text-align: center;
  font-weight: 900;
  border-bottom: 2px solid #cccccc;
}

.Hybrid {
  background-color: #70db70;
  border-color: #2eb82e;
}

.Sativa {
  background-color: #e6e600;
  border-color: #999900;
  color: #4d4d00;
}

.otherBadge {
  border: 1px solid #777
}

.container {
  padding: 0.5rem 0 3rem 0;
}

.imageContainer, .centeredColumns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image{
  width: 23rem;
  max-width: 100%;
}

.price {
  text-align: center;
  font-size: 3rem;
  color: #28a745;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topMargin {
  margin-top: 1rem !important;
}

.button {
  font-size: 1.25rem;
  max-width: 75%;
}

.container div {
  margin: 0;
}

.secondDiv {
  margin-top: 1rem !important;
}

.centeredColumns {
  justify-content: space-around;
  flex-wrap: wrap;
}

.badgeContainer {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.badgeContainer h3:nth-child(odd){
  padding-right: 1rem;
}

.productText {
  font-size: 1.25rem;
}

.productText.bold {
  font-weight: bold;
}
.divider{
  border: 1px solid gray;
  height: 2px;;
}
@media(min-width: 768px){
  .secondDiv {
    margin-top: 0;
  }
}
@media(min-width: 992px){
  .badgeContainer h3:nth-child(odd){
    padding: 0;
  }

  .badgeContainer h3{
    padding: 0 0.5rem;
  }
}