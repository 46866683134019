.imageContainer{
  display: flex;
  justify-content: center;
  max-height: 16rem;
}

.image {
  max-height: 12rem;
  width: auto;
  border: 1px solid #ccc;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}