input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.quantityContainer {
  display: flex;
  justify-content: center;
  touch-action: manipulation;
}

.quantityContainer button:first-child{
  margin-right: 0.25rem;
}

.quantityContainer button:last-child{
  margin-left: 0.25rem;
}

.quantityContainer button{
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quantityContainer input {
  width: 6rem;
  font-size: 1.25rem;
  text-align: center;
  border-radius: 0.25rem;
  border: 1px solid #ccc
}

.hideIncrement {
  display: none !important;
}
