.buttonContainer {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid;
  border-color: rgba(0,0,0,0.125);
}

.buttonContainer button {
  margin-left: 0;
  width: 100%;
  margin-bottom: 1rem;
}

@media(min-width: 576px){
  .buttonContainer button {
    margin-left: 0.5rem;
    width: auto;
  }
}