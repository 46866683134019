.bg-dark {
  background-color: black !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(48, 106, 57)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler, .navbar-toggler {
  border-color: #306a39 !important;
  padding: .25rem .5rem !important;
} 

.navbar-toggler-icon:hover,  .navbar-toggler-icon:focus {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(245, 245, 245)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  border-color: #306a39 !important;
  background-color: #306a39 !important;
}

.page-item .page-link{
  border-color: rgba(0,0,0,0.125) !important;
  color: #306a39 !important;
  background-color: #fcfcfc;
}

.page-item.disabled .page-link{
  border-color: rgba(0,0,0,0.125) !important;
  color: #306a39 !important;
  background-color: #d9d9d9;
}

.page-item.active .page-link{
  border-color: #rgba(0,0,0,0.125) !important;
  background-color: #306a39 !important;
  color: #fcfcfc !important;
}

.dropdown-menu {
  margin-top: -2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-color: black;
  width: 100%;
}

.dropdown-toggle{
  padding-left: 1rem !important;
}

.UploadImages_imageContainer__M8Enh div div canvas, .UploadImages_imageContainer__M8Enh div div div {
  max-height: 14rem;
  max-width: 10.8rem;
  margin: 1rem;
  border-radius: 0.25rem
}

.TestResults_pdf__30jEW div canvas{
  transform: scale(0.5);
  border: 2px solid #ccc;
  margin-top: -195px;
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations {
  display: none !important;
}

.TestResults_pdf__30jEW div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__input-container input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-clip: padding-box;
  line-height: 1.5;
  font-weight: 400;
  user-select: none;
}

.modal-footer {
  display: flex;
  flex-direction: column;
}

.modal-footer button {
  width: 100%;
}

@media(min-width: 768px){
  .TestResults_pdf__30jEW div canvas{
    transform: scale(0.7);
    margin-top: -120px;
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
  }
  
  .modal-footer button {
    width: auto;
  }
}

@media(min-width: 1024px){
  .TestResults_pdf__30jEW div canvas{
    transform: scale(1);
    margin-top: -5px;
  }
}