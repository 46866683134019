.badgeContentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.labelSpan {
  padding: 0 0 0 0.25rem;
}

.badgeContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.badge {
  margin: 0 0.25rem
}