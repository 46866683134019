.card {
  margin-bottom: 0.25rem;
  height: 220px;
}

.cardBody, .row, .column {
  height: 90%;
}

.column.imageCol{
  display: flex;
  align-items: center;
}

.imageContainer {
  max-width: 9rem;
  height: auto;
  overflow: hidden;
  max-height: 10rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  border-radius: 0.25rem;
}

.brand {
  font-size: 1.25rem;
  font-weight: 200;
  margin-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.strain {
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.price{
  font-weight: 900;
}

.quantity {
  margin-bottom: 0;
}

.delivery {
  font-size: 1.25rem;
  margin-bottom: 0.125rem;
}

.buttonContainer{
  display: flex;
}

.buttonContainer button, .buttonContainer a {
  margin-right: 0.25rem;
  margin-top: 0 !important;
}

.info {
  display: flex;
  padding-right: 0.5rem;
  align-items: center;
}

.info div{
  display: flex;
  align-items: center;
  padding-right: 1rem
}

.info div p:first-child{
  padding-right: 0.25rem;
} 

.info div p, .info p {
  margin-bottom: 0;
}

.hideOnMobile {
  display: None;
}

.cartContainer {
  margin-right: 1rem
}

@media(min-width: 768px){
  .card{
    height: 184px;
    width: 100%;
  }

  .cardBody, .row, .column {
    height: 100%;
  }

  .info {
    display: block;
  }

  .info div{
    display: block;
    text-align: right;
  }

  .showOnMobile {
    display: none;
  }

  .hideOnMobile {
    display: flex;
  }

  .hideOnMobile.info {
    flex-direction: column;
    align-items: flex-end;
  }

  .price {
    padding: 1rem;
    font-size: 1.25rem;
  }
}

@media(min-width: 992px){

}