.buyorsellContainer {
  display: flex;
  justify-content: space-around;
  padding-top: 1rem;
}

.buyorsellText {
  padding-top: 1rem;
  text-align: center;
}

.button {
  width: 9rem;
}

