

.navbar {
  padding: 0;
  height: 66px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #306a39;
}

.navbarBottom {
  padding-top: 20px;
  height: 40px;
  background-color: whitesmoke;
  color: black;
  text-decoration: none;
  border-top: 1px solid #306a39;
}

.navbarBottom p {
  cursor: pointer;
}

.maxHeight {
  height: 100%
}

.link {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  padding: 1rem !important;
  font-weight: bold;
  color: #306a39 !important;
  height: 100%;
}

.active {
  background-color: #306a39 !important;
  color: whitesmoke !important
}

.collapse {
  position: absolute;
  top: 66px;
  width: 100%;
  left: 0;
  z-index: 1;
}

.container {
  padding: 0 1rem !important; 
  height: 100%;
  justify-content: space-around;
  display: flex;
}

.dropdown {
  display: none;
}

.logo{
  height: 33px;
}

.showOnDesktop {
  display: none;
}

.countBadge {
  position: absolute;
  top: 10px;
  margin-left: -10px;
  padding: 0 .5rem;
  color: whitesmoke;
  background-color: #306a39;
  border-radius: 100%;
  border: 2px solid whitesmoke
}

.cartContainer {
  margin-right: 0.75rem
}

@media(min-width: 768px){
  .logo{
    height: 40px;
  }
}

@media(min-width: 992px) {
  .hideOnDesktop{
    display: none
  }

  .showOnDesktop {
    display: block;
  }

  .link {
    border: none;
    border-bottom: 5px solid transparent;
    background-color: #f5f5f5 !important;
    color: #306a39 !important;
    display: none;
  }

  .active{
    color: #306a39 !important;
    border-bottom: 5px solid #306a39 !important;
  }

  .collapse {
    position: relative;
    top: unset;
  }

  .container {
    padding: 0;
  }

  .userLinks {
    display: none;
  }

  .permanent {
    display: block !important;
  }

  .dropdown {
    display: flex;
    margin-top: 4px;
    border: 5px solid transparent;
  }

  .dropdown a:first-of-type{
    margin-top: -19px;
  }

  .dropdown a, .dropdown div {
    color: #306a39 !important;
    background-color: #f5f5f5 !important;
  }

  .dropdown div div{
    border-color: #306a39 !important;
  }

  .dropdown {
    align-items: center;
    height: 68px;
    font-weight: bold;
    color: #306a39 !important;
  }

  .cart {
    margin-left: 3rem
  }
}