.container {
 padding-top: 1rem;
 padding-bottom: 5.125rem;
}

.listOfLists {
  position: fixed;
  top: 82px;
  left: 0;
  transition: left linear .35s;
  z-index: 1;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 90px;
}

.listOfLists.hideList {
  left: -1500px;
}

.listPreview {
  position: fixed;
  top: 82px;
  left: 0;
  transition: left linear .35s;
  z-index: 1;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 90px;
}

.listPreview.hideList{
  left: 2000px;
}

@media(min-width: 768px){
  .listOfLists, .listPreview, .listOfLists.hideList, .listPreview.hideList {
    position: relative;
    top: unset;
    left: unset;
    overflow: unset;
  }
}