.card {
  margin-bottom: 0.25rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.infoContainer {
  text-align: center;
}

.column.imageCol{
  display: flex;
  align-items: center;
}

.imageContainer {
  max-width: 9rem;
  height: auto;
  overflow: hidden;
  max-height: 10rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  border-radius: 0.25rem;
}

.brand {
  font-size: 1.25rem;
  font-weight: 200;
  margin-bottom: 0.125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.strain {
  font-size: 1.25rem;
  font-weight: 900;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.price{
  font-weight: 900;
}

.quantity {
  margin-bottom: 0;
}

.delivery {
  font-size: 1.25rem;
  margin-bottom: 0.125rem;
}

.buttonContainer{
  display: flex;
}

.buttonContainer button, .buttonContainer a {
  margin-right: 0.25rem;
  margin-top: 0 !important;
}

.cartContainer {
  margin-right: 1rem
}

.quantity span{
  display: none;
}

@media(min-width: 768px){
  .container {
    flex-direction: row;
    justify-content: space-around;
  }

  .info {
    text-align: left;
    width: 50%;
  }

  .infoContainer{
    width: 60%;
    display: flex;
    flex-wrap: wrap;
  }

  .card{
    width: 100%;
  }

  .row {
    width: 50%;
    display: flex;
    flex-direction: column;
    text-align: right;
  }

  .cardBody, .row, .column {
    height: 100%;
    min-height: auto;
  }

  .price {
    font-size: 1.25rem;
  }
}

@media(min-width: 992px){

}