.toast {
  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 1051;
  transition: z-index .15s linear, opacity .15s linear;
}

.toastContainer {
  display: flex;
  justify-content: center;
}

.toast.success{
  border-color: #28a745;
  background-color: #5bd778;
  color: #0f3e1a
}

.header.success {
  background-color: #28a745;
  color: white;
}

.toast.danger{
  border-color: #dc3545;
  background-color: #e46774;
  color: #570f16
}

.header.danger {
  background-color: #dc3545;
  color: white;
}

.toast.hidden{
  z-index: -1;
}