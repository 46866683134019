.searchBar {
  width: 100%;
}

.searchBarContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.formGroup{
  margin-bottom: 0.25rem;
}

.button{
  width: 100%;
}

@media(min-width: 576px) {
  .searchBar{
    width: 80%;
  }

  .formGroup{
    margin-bottom: 1rem;
  }
}

@media(min-width: 992px) {
  .searchBar{
    width: 60%;
  }
}