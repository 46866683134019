.actions{
    display: flex;
    justify-content: flex-end;
}
.actions button{
    margin-left: 0.25rem;
}
.image {
    max-height: 12rem;
    width: auto;
    border: 1px solid #ccc;
    margin: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }