.loginButton {
  display: flex;
  justify-content: flex-end;
}

.register{
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid #ccc;
}