.topContainer{
    margin-top: 10px;
    display: flex;  
    flex-wrap: wrap;
    justify-content: center;
}
.header{
  font-size: 40px;
  border-bottom: 1px solid #ccc;
  width: 100%;
}
.content{
    width: 200px;
  }
  .chartTitle{
    padding: 5px;
  }
  .tile{
    display: flex;
    border: 1px solid #ccc;
    text-align: center;
    margin: 5px;
    width:250px;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    border-radius: .5rem;
  }
  .icon{
    height: 60px;
    padding: 5px;
    margin-top: 8px;
    margin-left: 15px;
  }
  .title{
    padding: 5px;
    margin-bottom: -10px;
  }
  .body{
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .chartContainer{
    border: 1px solid #ccc;
    width: 48%;
    margin: 5px;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
  }
  table { 
    width: 100%; 
    border-collapse: collapse; 
  }
  /* Zebra striping */
  tr:nth-of-type(odd) { 
    background: #eee; 
  }
  th { 
    background: #333; 
    color: white; 
    font-weight: bold; 
  }
  td, th { 
    padding: 6px; 
    border: 1px solid #ccc; 
    text-align: left; 
  }
  @media(max-width: 768px){
    .tile{
      width: 48%;
    }
    .chartContainer{
      width: 100%;
    }
  }
  @media(max-width: 548px){
    .tile{
      width: 100%;
    }
    .chartContainer{
      width: 100%;
    }
  }
  @media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #ccc; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Brand"; }
	td:nth-of-type(2):before { content: "Available Amount"; }
}
