.item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: 5px;
}
.itemLabel{
  pointer-events: none;
}