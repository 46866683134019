.page{
    background-color: #000000;
    overflow: hidden; 
    width: 100%;
}
.container{
    padding-top: 1rem;
    padding-bottom: 5.125rem;
}
.registerCard{
    margin-top: 1rem;
    border-radius: 0.5rem;
    background-color:whitesmoke;
}
.registrationButton{
    display: flex;
    justify-content: center;
}