* {
    box-sizing: border-box;
  }
  .group{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
  }

  .cardBody {
    background-color: white !important;
    color: black !important;
    padding: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .cardHeader {
    text-align: center;
  }

  .row {
    width: 100%;
  }

  .col{
    padding-bottom: 0.5rem;
  }

  .content{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around
  }
  .columns {
    float: left;
    width: 50%;
    padding: 8px;
  }
  .featureItems{
    list-style-type: none;
    font-size: 2rem;
  }
  .price {
    list-style-type: none;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 0 0 0.25rem 0.25rem
  }
  .image{
    height: 200px;
  }
  .items{
    overflow: auto;
  }
  .price .header {
    background-color: #111;
    color: white;
    font-size: 25px;
  }
  
  .price li {
    border-bottom: 1px solid #eee;
    padding: 20px;
    text-align: center;
  }
  
  .price .grey {
    background-color: #eee;
    font-size: 20px;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  
  .button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
  }
  .hideOnMobile{
    display:none;
}
  @media(max-width: 768px) {
    .columns {
      width: 100%;
    }   
  }
  @media(min-width: 992px){
    .hideOnMobile{
        display: block;
    }
  }
 